
export class MyLibrary{

    static getDateDMY(d){
        if(d instanceof Date){
            return d.getDate().toString().padStart(2, '0') + '/' + (d.getMonth() + 1).toString().padStart(2, '0') + '/' + d.getFullYear();
        }
        else{
            let ds = d.split('T')[0].split('-');
            let r = ds[2] + '/' + ds[1] + '/' + ds[0];
            return r;
        }
    }

    static getDateMDY(d){
        if(d instanceof Date){
            return (d.getMonth() + 1).toString().padStart(2, '0') + '/' + d.getDate().toString().padStart(2, '0') + '/' + d.getFullYear();
        }
        else{
            let ds = d.split('T')[0].split('-');
            let r = ds[1] + '/' + ds[2] + '/' + ds[0];
            return r;
        }
    }

    static getDateYMD(d){
        if(d instanceof Date){
            return  d.getFullYear() + '/' + (d.getMonth() + 1).toString().padStart(2, '0') + '/' + d.getDate().toString().padStart(2, '0');
        }
        else{
            let ds = d.split('T')[0].split('-');
            let r = ds[0] + '/' + ds[1] + '/' + ds[2];
            return r;
        }
    }

    static getHours(useHalves){
        let hours = [];
        for(let i = 0; i < 24 ; i ++){
            hours.push(i.toString().padStart(2, '0') + ':00');
            if(useHalves){
                hours.push(i.toString().padStart(2, '0') + ':15');
                hours.push(i.toString().padStart(2, '0') + ':30');
                hours.push(i.toString().padStart(2, '0') + ':45');
            }
        }
        return hours;
    }
}