import React, { useState, useEffect, Fragment } from 'react';
import { AuthService } from '../../Services/AuthService';
import { EventService } from '../../Services/EventService';
import { Redirect } from 'react-router';
import { Row, Col, Card, Form, FormGroup, Input, Label, Button, Alert, Table, CardBody, NavLink } from 'reactstrap';
import { MyLibrary } from '../../Services/MyLibrary';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleDown, faEdit, faPlusSquare, faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import FileService from '../../Services/FileService';
import DateTimePicker from 'react-datetime-picker';
import useForceUpdate from 'use-force-update';
import axios from 'axios';


const Home = () => {

    const [html, setHtml] = useState('');

    useEffect(() => {
        axios.get('/home.html' ).then(r => {
            console.log(r.data);
            setHtml(r.data)
        });
    }, [])

    return <Fragment >
        {
            html && <div dangerouslySetInnerHTML={{__html: html}}></div>
        }
        {
            !html && <h3>Loading...</h3>
        }
    </Fragment>
}

export default Home;