import React,  { useState, useEffect } from 'react';
import { Container, Input, Row, Col, Button, Form, FormGroup, Card, CardBody, Alert } from 'reactstrap';
import './ResetAndLogin.css';
import { AuthService } from '../../Services/AuthService';
import { useHistory, useParams } from 'react-router';
import { NavLink, Redirect } from 'react-router-dom';

const ResetAndLogin = (props) => {
    const authService = new AuthService();
    const [email, setEmail] = useState('');
    if(useParams()){
        const { code } = useParams() ? useParams() : {code: ''};
    }
    const [successMessage, setSuccessMessage] = useState('');
    const { code } = useParams();
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [loggingIn, setLoggingIn] = useState(true);
    const history = useHistory();
    const [user, setUser] = useState(localStorage.getItem('user') ? JSON.parse(localStorage.getItem('digitalEventUser')) : '');

    const { updateUser } = props;

    useEffect(() => {
        if(code){
            doResetPassword();
        }
    }, []);

    const submitLogin = (e) => {
        e.preventDefault();
        //console.log('submitted: ' + email + '-' + password);
        authService.login(email, password).then(logindata =>{
            updateUser(logindata);
            history.push('/networking');
        }).catch(e => {
            console.log(e);
            setErrorMessage(e);
            setTimeout(() => {
                setErrorMessage('');
            }, 3000);
        });
    };

    const initResetPassword = () => {
        if(!email){
            setErrorMessage('You have to complete your email');
            setTimeout(() => {
                setErrorMessage('');
            }, 3000);
        }
        else{
            authService.requestResetPassword(email).then(r => {
                setSuccessMessage('An email has been sent to you with instructions on how to reset your password');
                setTimeout(() => {
                    setSuccessMessage('')
                }, 4000);
            }).catch(e => {
                console.log(e);
            });
        }
    };

    const doResetPassword = () => {
        if(code){
            authService.performResetPassword(code).then(r => {
                setSuccessMessage('Your password was reset and your new Password was sent to your email. Please use your new password to login.');
                setTimeout(() => {
                    setSuccessMessage('');
                }, 6000);
            }).catch(e => {
                setErrorMessage('This code has already been used');
                setTimeout(() => {
                    setErrorMessage('');
                }, 3000)
            });
        }
    }

    if(user){
        return <Redirect to="/networking" />
    }
    return (
        <Container fluid className="loginPage">
            <Row className="">
                <Col md="4" >
                        
                </Col>
                <Col md="4" >
                    <div>
                        <Card>
                            <CardBody>
                                <Form onSubmit={submitLogin}>
                                    {
                                        errorMessage && <Alert color="danger">{errorMessage}</Alert>
                                    }
                                    {
                                        successMessage && <Alert color="success">{successMessage}</Alert>
                                    }
                                    <FormGroup>
                                        <Input type='email' className="mycustomInput" placeholder="Email"
                                            onChange={(e) => { setEmail(e.target.value) }} />
                                    </FormGroup>
                                    <br />
                                    {
                                        loggingIn && <>
                                            <FormGroup>
                                                <Input type='password' placeholder="Password" className="mycustomInput" 
                                                    onChange={(e) => { setPassword(e.target.value) }} />
                                            </FormGroup>
                                            <br />
                                            <div className="d-grid gap-2">
                                                <Button color="primary" className="myCustomButton" size="sm" block>Login</Button>
                                            </div>
                                        </>
                                    }
                                    {
                                        !loggingIn && <>
                                            <div className="d-grid gap-2">
                                                <Button color="warning" className="myCustomButton" size="sm" block type="button" onClick={initResetPassword}>Reset Password</Button>
                                            </div>
                                        </>
                                    }

                                    <div className="d-grid gap-2">
                                        <NavLink className="btn btn-link btn-sm" to="/networking/register">Create Account</NavLink>
                                    </div>
                                    {loggingIn && 
                                    <div className="d-grid gap-2">
                                        <Button color="link" className="text-warning" size="sm" type="button" onClick={() => setLoggingIn(false)}>I forgot my password</Button>
                                    </div>
                                    }
                                    {
                                        !loggingIn && <div className="d-grid gap-2">
                                            <Button color="link" className="text-success" size="sm" type="button" onClick={() => setLoggingIn(true)}>Login</Button>
                                        </div>
                                    }
                                    
                                </Form>
                            </CardBody>
                        </Card>
                        
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default ResetAndLogin;