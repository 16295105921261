import axios from 'axios';
import settings from './settings';

export default class BaseService{
    api;
    constructor(){
        
        this.api = axios.create({
            baseURL: settings.backend,
            
            withCredentials: true
        });
    }    
}