import React, { useState, useEffect } from 'react';
import { AuthService } from '../../Services/AuthService';
import { Row, Col, Card, Button, Input } from 'reactstrap';
import FileService from '../../Services/FileService';
import { Redirect } from 'react-router';
import { useHistory } from 'react-router';
import { EventService } from '../../Services/EventService';

const Expo = () => {
    const [companies, setCompanies] = useState([]);
    const [search, setSearch] = useState('');
    const [event, setEvent] = useState({});
    const [activities, setActivities] = useState([]);
    const [sector, setSector] = useState('');
    const history = useHistory();
    const eventService = new EventService();
    const fileService = new FileService();
    const authService = new AuthService();
    const user = JSON.parse(localStorage.getItem('digitalEventUser'));
    if(!user){
        return <Redirect to="/networking/login" />
    }

    useEffect(() => {
        authService.getCompanies('').then(r => setCompanies(r.filter(c => c.companyId !== 1 && c.companyId !== 2)));
        eventService.getEvent(1).then(r => {
            setEvent(r);
            setActivities(JSON.parse(r.companyActivities));
        });
    }, []);

    const goToCompany = (companyId) => {
        history.push(`/company/${companyId}`)
    };

    return <>
        <Input type="search" className="mycustomSearch2 float-end" onChange={(e) => setSearch(e.target.value)} placeholder="Search Businesses"/>{" "}
        <Input type="select" className="float-end mycustomSearch2sd" style={{width: '300px'}} onChange={(e) => setSector(e.target.value)}>
            <option value="">Select Sector</option>
            {
                event.companyActivities && JSON.parse(event.companyActivities).map((ca, i) => {
                    return <option key={i} value={ca.name}>{ca.name}</option>
                })
            }
        </Input>
        <h3 className="text-primary" style={{ margin: '20px' }}>List of Businesses 
        {' '}<span className="color-secondary" style={{fontSize: '14px'}}>Showing {companies.filter(c => 
                    c.name.toUpperCase().includes(search.toUpperCase()) 
                    || c.description.toUpperCase().includes(search.toUpperCase()) || c.city.toUpperCase().includes(search.toUpperCase()) 
                    || c.title.toUpperCase().includes(search.toUpperCase())
                    || c.address.toUpperCase().includes(search.toUpperCase()) || c.country.toUpperCase().includes(search.toUpperCase())).length} 
                    {' '} of {companies.length} </span> 
        </h3>
        <Row style={{margin: '15px'}}>
            {
                companies.filter(c => (c.name.toUpperCase().includes(search.toUpperCase()) || c.description.toUpperCase().includes(search.toUpperCase()) 
                    || c.city.toUpperCase().includes(search.toUpperCase())
                    || c.title.toUpperCase().includes(search.toUpperCase()) || c.address.toUpperCase().includes(search.toUpperCase()) 
                    || c.country.toUpperCase().includes(search.toUpperCase())) && (!sector || sector === c.activity)).map(c => {
                        return <Col md="3" style={{marginBottom: '30px'}}>
                            <Card style={{display: 'block'}}>
                                <div class="text-center">
                                {
                                    c.logo ? <img src={fileService.getUrl(JSON.parse(c.logo)[0].name)} style={{maxHeight: '100px'}} /> : ''
                                }
                                </div>
                                <h3>{c.title}</h3>
                                <h6 className="text-primary">{c.city + ', ' + c.country}</h6>
                                
                                <br />
                                {
                                    c.activity && <Button size="sm" disabled style={{background: activities.filter(a => a.name === c.activity)[0] ? activities.filter(a => a.name === c.activity)[0].color : 'success'}}>{c.activity}</Button>
                                }   
                                <p dangerouslySetInnerHTML={{__html: c.description.substr(0, 150) + (c.description.length > 150 ? '...': '')}}>
                                    
                                </p>
                                <Button color="primary" size="sm" style={{width: 'auto!important'}} onClick={() => goToCompany(c.companyId)}>View Profile</Button>
                            </Card>
                        </Col>
                    })
            }
        </Row>
    </>
}

export default Expo;