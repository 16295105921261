import React from 'react';
import { NavLink } from 'react-router-dom';
import { Row, Col } from 'reactstrap';

const Footer = () => {
        return <Row className="mt-auto fixed-bottom" style={{ background: '#ddd', padding: '20px' }}>
            
            <Col md="3" className="text-center">
                Western Greece B2B | Netourwork.com
            </Col>
            <Col md="3" className="text-center">
                <NavLink to="/privacy" >Privacy Policy</NavLink>
            </Col>
            <Col md="3" className="text-center">
                <NavLink to="/terms" >Terms of Use</NavLink>
            </Col>
            <Col md="3" className="text-center">
                <NavLink to="/support">Tech Support</NavLink>
            </Col>
        </Row>
};

export default Footer;