import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Container, Row, Col, Navbar, NavbarToggler, NavbarBrand, 
    Collapse, Nav, NavItem, NavLink, UncontrolledDropdown, DropdownToggle, 
    DropdownMenu, DropdownItem, NavbarText, Form, Input } from 'reactstrap';
import LoginComponent from './Components/LoginComponent/LoginComponent';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import "typeface-roboto";
import { useHistory } from 'react-router';
import { faBell, faUser } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import RegisterComponent from './Components/RegisterComponent/RegisterComponent';
import { AuthService } from './Services/AuthService';
import Content from './Components/Content/Content';
import { EventService } from './Services/EventService';
import FileService from './Services/FileService';
import TopBar from './Components/Content/TopBar';
import Networking from './Components/Content/Networking';
import ArrangeMeeting from './Components/Content/ArrangeMeeting';
import Manage from './Components/Content/Manage';
import ManageBooth from './Components/Content/ManageBooth';
import Profile from './Components/Content/Profile';
import Expo from './Components/Content/Expo';
import UserProfile from './Components/Content/UserProfile';
import Company from './Components/Content/Company';
import Agenda from './Components/Content/Agenda';
import Home from './Components/Content/Home';
import ResetAndLogin from './Components/ResetAndLogin/ResetAndLogin';
import Stage from './Components/Content/Stage';
import ManageBoothAdmin from './Components/Content/ManageBoothadmin';
import Footer from './Components/Content/Footer';
import EditUser from './Components/Content/EditUser';
import Support from './Components/Content/Support';
import Privacy from './Components/Content/Privacy';
import Terms from './Components/Content/Terms';
import 'react-quill/dist/quill.snow.css';

function App() {
  const [isOpen, setIsOpen] = useState(false);
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('digitalEventUser')));
  const [activeMenu, setActiveMenu] = useState(window.location.pathname);
  const [event, setEvent] = useState({});
  const [notifications, setNotifications] = useState([]);
  const authService = new AuthService();
  const eventService = new EventService();
  const fileService = new FileService();
  const history = useHistory();
  

  const fetchNotifications = () => {
    if(user){
      authService.getNotifications().then(r => setNotifications(r));
    }
  }

  const clearNotifications = () => {
    authService.readAllNotifications().then(r1 => authService.getNotifications().then(r => setNotifications(r)))
  };

  const readNotification = (notificationId) => {
    authService.readNotification(notificationId).then(r1 => authService.getNotifications().then(r => setNotifications(r)));
  }

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const logout = () => {
    localStorage.setItem('digitalEventUser', null);
    authService.logout().then(r => {
      setUser(null);
      history.push('/');
    }).catch(e => console.log(e));
    
  }

  return (
    <Router>

      <Container fluid style={{paddingBottom: '70px'}}>
        <TopBar user={user} setUser={setUser}></TopBar>
        <Route exact path="/" component={() => <Home />} />
        <Route exact path="/networking" component={() => <Networking />} />
        <Route exact path="/networking/login" component={() => <LoginComponent updateUser={setUser} />} />
        <Route exact path="/networking/login/:code" component={() => <ResetAndLogin updateUser={setUser} />} />
        <Route exact path="/networking/register" component={() => <RegisterComponent />} />
        <Route exact path="/expo" component={() => <Expo />} />
        <Route exact path="/company/:companyId" component={() => <Company />} />
        <Route exact path="/networking/profile" component={() => <Profile />} />
        <Route exact path="/networking/appoint/:otherUserId" component={() => <ArrangeMeeting />} />
        <Route exact path="/networking/profile/:userId" component={() => <UserProfile />} />
        <Route exact path="/networking/edituser/:userId" component={() => <EditUser />} />
        <Route exact path="/manage" component={() => <Manage />} />
        <Route exact path="/agenda" component={() => <Agenda />} />
        <Route exact path="/stage" component={() => <Stage />} />
        <Route exact path="/support" component={() => <Support />} />
        <Route exact path="/privacy" component={() => <Privacy />} />
        <Route exact path="/terms" component={() => <Terms />} />
        <Route exact path="/managebooth" component={() => <ManageBooth />} />
        <Route exact path="/managebooth/:companyId" component={() => <ManageBoothAdmin />} />
        <Footer>
        </Footer>
      </Container>
    </Router>
  );
}

export default App;
