import BaseService from './BaseService';

export class EventService extends BaseService{

    getParticipants(eventId){
        const participantsPromise = new Promise((resolve, reject) => {
            this.api.get(`events/participants/${eventId}`).then(response => {
                resolve(response.data);
            }).catch(e => reject(e));
        });
        return participantsPromise;
    }

    getEvent(eventId){
        const eventPromise = new Promise((resolve, reject) => {
            this.api.get(`events/${eventId}`).then(response => {
                resolve(response.data);
            }).catch(e => reject);
        });
        return eventPromise;
    }

    saveEvent(event){
        const saveEventPromise = new Promise((resolve, reject) => {
            this.api.post('events', event).then(r => resolve(r.data)).catch(e => reject(e));
        });
        return saveEventPromise;
    }

    saveEventSession(eventSession){
        const saveSessionPromise = new Promise((resolve, reject) => {
            this.api.post('events/eventsession', eventSession).then(r => resolve(r.data)).catch(e => reject(e));
        });
        return saveSessionPromise;
    }

    deleteEventSession(esId){
        const deleteSessionPromise = new Promise((resolve, reject) => {
            this.api.delete(`events/eventsession/${esId}`).then(r => resolve(r)).catch(e => reject(e));
        });
        return deleteSessionPromise;
    }

    getSessions(){
        const getSessionsPromise = new Promise((resolve, reject) => {
            this.api.post('events/eventsessions', {}).then(r => resolve(r.data)).catch(e => reject(e));
        });
        return getSessionsPromise;
    }
    
}