import BaseService from './BaseService';

export class AppointmentService extends BaseService{


    getAppointments(userId){
        const getAppointmentsPromise = new Promise((resolve, reject) => {
            this.api.get(`appointments/${userId}`).then(response => {
                resolve(response.data);
            }).catch(e => reject());
        });
        return getAppointmentsPromise;
    }
    
    requestAppointment(userIdReceiver, datetime, message){
        const requestAppointmentPromise = new Promise((resolve, reject) => {
            this.api.post('appointments/request', {userIdReceiver: parseInt(userIdReceiver), datetime, message}).then(response => {
                resolve(response.data);
            }).catch(e => reject());
        });
        return requestAppointmentPromise;
    }

    acceptAppointment(appointmentId){
        const aaPromise = new Promise((resolve, reject) => {
            this.api.post('appointments/accept', {appointmentId}).then(response => {
                resolve(response.data);
            }).catch(e => reject());
        });
        return aaPromise;
    }
    
    rejectAppointment(appointmentId, messageRespond){
        const raPromise = new Promise((resolve, reject) => {
            this.api.post('appointments/reject', {appointmentId, messageRespond}).then(response => resolve(response.data)).catch(e => reject());
        });
        return raPromise;
    }

    cancelAppointment(appointmentId){
        const caPromise = new Promise((resolve, reject) => {
            this.api.post('appointments/cancel', {appointmentId}).then(response => resolve(response.data)).catch(e => reject());
        });
        return caPromise;
    }

    completeAppointment(appointmentId){
        const caPromise = new Promise((resolve, reject) => {
            this.api.post('appointments/complete', {appointmentId}).then(response => resolve(response.data)).catch(e => reject());
        });
        return caPromise;
    }
    startAppointment(appointmentId){
        const caPromise = new Promise((resolve, reject) => {
            this.api.post('appointments/joinedappointment', {appointmentId}).then(response => resolve(response.data)).catch(e => reject());
        });
        return caPromise;
    }

    getAllAppointments(){
        const allAppointmentsPromise = new Promise((resolve, reject) => {
            this.api.post('appointments/getall', {}).then(response => resolve(response.data)).catch(e => reject(e));
        });
        return allAppointmentsPromise;
    }
    
}