import axios from 'axios';

class FileService {
    uploadFile(file){
        const fileDataPromise = new Promise((resolve, reject) => {
            const formData = new FormData();
            console.log(file);
            formData.append('files', file);
            console.log(formData);
            axios.post('https://filemanager.ekapps.tech', formData, {
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
            }).then((response) => {
                resolve(JSON.stringify(response.data));
            }).catch(e => {
                reject(e);
            });
        });
        return fileDataPromise;
    }

    getUrl(path){
        return 'https://filemanager.ekapps.tech/uploads/' + path;
    }
}

export default FileService;