import React, { useState, useEffect, Fragment } from 'react';
import { AuthService } from '../../Services/AuthService';
import { EventService } from '../../Services/EventService';
import { Redirect } from 'react-router';
import { Row, Col, Card, Form, FormGroup, Input, Label, Button, Alert, Table, CardBody, NavLink, Container } from 'reactstrap';
import { MyLibrary } from '../../Services/MyLibrary';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleDown, faEdit, faPlusSquare, faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import FileService from '../../Services/FileService';
import DateTimePicker from 'react-datetime-picker';
import useForceUpdate from 'use-force-update';
import axios from 'axios';


const Support = () => {

    const [html, setHtml] = useState('');

    

    return <Fragment >
        <Container>
            <Card>
                <h2>Tech Support</h2>
                <p>
                    For tech support you can call the following numbers:<br />
                    +306944825484<br />
                    +306978896711
                </p>
                <Row>
                    <Col md="6" className="text-center">
                        <h2 className="text-center text-primary">Instructions for Suppliers</h2>
                        <a className="btn btn-lg btn-primary" href="/suppliers.pdf" style={{width: '100%'}}>Download the Instructions</a>
                    </Col>
                    <Col md="6" className="text-center">
                        <h2 className="text-center text-primary">Instructions for Buyers</h2>
                        <a className="btn btn-lg btn-primary" href="/buyers.pdf" target="_blank" style={{width: '100%'}}>Download the Instructions</a>
                    </Col>
                </Row>
            </Card>
        </Container>
        
    </Fragment>
}

export default Support;