import React, { useState, useEffect, Fragment } from 'react';
import { AuthService } from '../../Services/AuthService';
import { EventService } from '../../Services/EventService';
import { Redirect } from 'react-router';
import { Row, Col, Card, Form, FormGroup, Input, Label, Button, Alert, Table, CardBody, NavLink, Container } from 'reactstrap';
import { MyLibrary } from '../../Services/MyLibrary';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleDown, faEdit, faPlusSquare, faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import FileService from '../../Services/FileService';
import DateTimePicker from 'react-datetime-picker';
import useForceUpdate from 'use-force-update';
import axios from 'axios';


const Privacy = () => {

    const [html, setHtml] = useState('');

    useEffect(() => {
        axios.get('/home.html' ).then(r => {
            console.log(r.data);
            setHtml(r.data)
        });
    }, [])

    return <Fragment >
        <Container>
            <Card>
                <h2 className="text-primary">Privacy Policy</h2>
                <h4>Personal Data Protection Policy</h4>
                <p>
                    Welcome to the website www.digitalevent.gr. We take personal data protection issues 
                    responsibly and sensitively, and for this reason we have created this policy so that you 
                    can find out how your data is processed by our Company.
                </p>
                <h4>1. Introduction – Data Processing Controller</h4>
                <p>The website at www.digitalevent.gr, hereinafter referred to as “the Site”, belongs to KAINOTOMES DIKTYOSEIS O.E. - NETOURWORK 
                    (unofficial translation: INNOVATIVE NETWORKS GP) (General Partnership), holder of Tax ID number 801038180 issued by the 
                    Marousi Tax Office, which has its registered offices at 17, Parou Street, Melissia, Athens, PC 15127, here in after referred 
                    to as “the Company” or “we”, having as its purpose the provision of tourist and other online promotional and advertising services, 
                    as well as the organisation of training seminars for professionals,
                    Telephone: 2108033473, Email: info@netourwork.com.
                    In order to be able to provide our services and also to comply with our legal obligations, we process certain 
                    information about visitors to and members of the Site. This data could lead to their identification, either directly 
                    or indirectly. Under the current legal framework, some of this information is “personal data,” while you, as a guest 
                    or member, are considered to be “data subjects” and we, as a company, are the “data processors” of your data.
                    The purpose of our policy is to provide you, in an easily understandable and concise way, with information about 
                    which personal data we process, for what purpose, with whom we share that information, and about your rights and 
                    how to exercise them. If you have any questions about the processing of your data or the exercising of your rights, 
                    please feel free to
                    contact us at privacy@netourwork.com</p>
                <h4>2. The basic principles of data processing</h4>
                <p>We undertake to process your data in a fair and transparent manner, in accordance with the applicable legal framework, namely the 
                    General Data Protection Regulation (GDPR), Law 3471/2006, other relevant legislation and the decisions of the Personal Data Protection 
                    Authority.</p>
                <p>Simply put, this means that:</p>
                <ul>
                    <li>
                        We process your data only for purposes that are explicit, legitimate and determined from
                        the outset, and we do not process them further in any manner incompatible with these
                        purposes (limitation of purpose)
                    </li>
                    <li>
                        We process only data that is appropriate, relevant and necessary for the intended purposes
                        set for processing (minimisation)
                    </li>
                    <li>
                        We make every effort to ensure that your data is accurate, giving you, where appropriate,
                        the ability to have it corrected/deleted
                    </li>
                    <li>
                        We retain your data in a format that allows us to identify you only for the time required for
                        he intended purposes set for processing (storage period limitation)
                    </li>
                    <li>
                        We make every effort to safeguard the security of your data, protecting it from unauthorised
                        or illegal processing and accidental loss, destruction or damage.
                    </li>
                </ul>
                <p>
                    Within the framework for protection of your data, the Company implements a range of appropriate technical and 
                    organisational measures, adopts internal security policies, trains its staff, who are committed to maintaining 
                    confidentiality and secrecy, and exploits a range of technologies to ensure that your data is secure (e.g. SSL certificates, 
                    encryption, certified hosting providers).
                    In strict compliance with the principles of information security and data protection, we regularly monitor security 
                    measures and, if necessary, adapt them to comply with new best practices.
                </p>
                <h4>3. Which personal data do we process?</h4>
                <p>
                    First of all, the Company only processes your data when you actively provide it yourself (such as when you 
                    fill out a contact form or when you sign up).
                    This rule does not apply to certain data collected through cookies (see in detail here) and some data that we 
                    automatically collect during your visit to the Site.
                </p>
                <h5>A. Information gathered automatically</h5>
                <p>
                    When you visit our Site, our server records your IP address, which according to applicable law is personal data, 
                    even if we cannot identify you on the basis of this information alone. In addition, we collect certain other information, 
                    such as the date and time of your visit, the page from which your visit came, the type of browser, and the operating 
                    system you use to visit the Website.
                </p>
                <p>
                    The processing of such data by us rests (its legal basis) on the Company’s legitimate interest, since it is 
                    technically necessary both for the operation of the Site and to safeguard networks, information and services 
                    from random events or illegal or malicious actions which compromise the availability, authenticity, integrity 
                    and confidentiality of stored or transmitted data (e.g. denial of service (DOS) attacks).
                </p>
                <p>
                    This processing is in line with the relevant legal framework, given that it does not carry 
                    serious risks to your rights and freedoms
                </p>
                <h5>Β. Information you provide to us</h5>
                <p>
                    We process personal information you provide to us in the following cases:
                </p>
                <h6>I. Communications with the Company via contact form/email</h6>
                <Table>
                    <thead>
                        <tr>
                            <th>
                                Data processed by us
                            </th>
                            <th>
                                Purpose
                            </th>
                            <th>
                                Legal Basis
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                Full name
                                Email address Telephone numb (in the case of a contact form) 
                                Important note: I your message to the company, yo should only provide the information 
                                necessary for th matter you are interested in and avoid mentioning your personal information or th of third parties.
                            </td>
                            <td>
                                We process this data as part of the service we provide to you, in order to be able to contact you 
                                in response to your message.
                                Sending an email or submitting a form does not make you our “customer”, and we do not enter your information 
                                in our database. However, we may use your details to ask for your permission to include you in our database.
                            </td>
                            <td>
                                We process the data you provide to us based on 
                                your consent (Article 6 (1a) of the GDPR), which you 
                                have the right to revoke at any time. You also have 
                                the right to request the deletion of your data at any time. 
                                In the event of revocation, the legality of the processing 
                                that has taken place up to that point in time is not affected. Revoking consent means we are no longer able 
                                to communicate with you.
                            </td>
                        </tr>
                    </tbody>
                </Table>
                <h6>II. Creation of an Account</h6>
                <Table>
                    <thead>
                        <tr>
                            <th>
                                Data processed by us
                            </th>
                            <th>
                                Purpose
                            </th>
                            <th>
                                Legal Basis
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                User name<br />
                                Email address<br />
                                Profile picture (optional)<br />
                                Name (optional)<br />
                                Address (optional)<br />
                                Work address (optional)<br />
                                Telephone number (optional)<br />
                                Business activity (optional)<br />
                                Job title (optional)<br />
                                Social media profile (optional)
                            </td>
                            <td>
                                We process this data at your request in order to create your profile on the Site and so that you can use our services.
                                In addition, we process these data to facilitate networking and communication among site users. In particular, 
                                we provide you with the ability to submit and accept requests from members of the Site in order to exchange 
                                information and to communicate with each other. In order for your information to be visible to other 
                                members and to communicate with each other, you will first have to accept their request.
                            </td>
                            <td>
                                We process the data you provide to 
                                us in order to take appropriate 
                                measures before entering into a 
                                contract with you as well as to fulfill 
                                our contractual obligations 
                                (Article 6 (1b) GDPR).
                            </td>
                        </tr>
                    </tbody>
                </Table>
                <h6>IΙΙ. Newsletter</h6>
                <Table>
                    <thead>
                        <tr>
                            <th>
                                Data processed by us
                            </th>
                            <th>
                                Purpose
                            </th>
                            <th>
                                Legal Basis
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                User name<br />
                                Email address<br />
                                Profile picture (optional)<br />
                                Name (optional)<br />
                                Address (optional)<br />
                                Work address (optional)<br />
                                Telephone number (optional)<br />
                                Business activity (optional)<br />
                                Job title (optional)<br />
                                Social media profile (optional)
                            </td>
                            <td>
                                We process this data at your request in order to create your profile on the Site and so that you can use our services.
                                In addition, we process these data to facilitate networking and communication among site users. In particular, 
                                we provide you with the ability to submit and accept requests from members of the Site in order to exchange 
                                information and to communicate with each other. In order for your information to be visible to other 
                                members and to communicate with each other, you will first have to accept their request.
                            </td>
                            <td>
                                We process the data you provide to 
                                us in order to take appropriate 
                                measures before entering into a 
                                contract with you as well as to fulfill 
                                our contractual obligations 
                                (Article 6 (1b) GDPR).
                            </td>
                        </tr>
                    </tbody>
                </Table>
                <p>
                    Important note: Responsibility for the accuracy of the information submitted in all cases 
                    is borne by the person submitting it. You may change your personal information at any time 
                    through your personal account. Find out about correcting your personal information in the 
                    section of the policy statement concerned with your rights.
                </p>
                <h4>4. Who has access to your data</h4>
                <p>
                    As a rule, access to your data is granted to authorised personnel of the Company, who process 
                    your data in the strictest confidentiality, and only to the extent and in the context of the 
                    purposes which we have already outlined for you.
                </p>
                <p>
                    Furthermore, in order to be able to provide our services, we share some of your data with affiliated companies. 
                    These companies (data processors) process your data only for the purposes mentioned above and only for and 
                    on behalf of the Company, with the exception of any legal obligations. During transmission of your data, 
                    the Company takes all necessary technical and organisational measures to ensure the best possible level of security.
                    In the context of the provision of its services, the Company has the right to disclose your business details 
                    (name, surname, business name, address, email, telephone) to the organisations responsible for providing the 
                    training services you have received, with the object of verifying participation and for future communications with you. 
                    You reserve the right to object to this processing of your data at any time by submitting a statement to the organisation concerned.
                </p>
                <p>
                    One of the key criteria for choosing our partners is their adherence to the rules for the safe processing 
                    of your data, and our partners are also contractually bound to provide guarantees and take appropriate technical 
                    and organisational measures to ensure that processing is conducted legally and to ensure the protection of your 
                    data and your rights.
                    These companies provide us with: (a) web hosting services, (b) commercial marketing services of the services 
                    we provide to you, (c) video recordings, sound recordings and live streaming services.
                </p>
                <h4>5. Where and for how long do we store your data</h4>
                <p>
                    Your data is stored in the Company’s information system, which is hosted in a data centre located in Germany and any 
                    backups are held on servers within the European Union. In any case, appropriate organisational and technical measures 
                    are always applied to avoid any violation. Data is stored exclusively for the period of time necessary for the purpose 
                    in each case. For example, if you have registered with the Site, the data remains stored for as long as you remain 
                    registered. If your account remains inactive for a long time, the Company will notify you and if there is no response, 
                    it may delete all of your data, except for whatever details are necessary to comply with legal obligations or any pursuit of claims.
                </p>
                <h4>6. What are your rights regarding your data and how can you exercise them</h4>
                <p>
                    Based on the respective legal framework, you have a number of rights in relation to the processing of your data by the Company.
                </p>
                <p>
                    Specifically, you have the right to:
                </p>
                <ol>
                    <li>
                        Submit a request to the Company to inquire whether we process data and, if so, which information (right of access)
                    </li>
                    <li>
                        Ask for correction of personal information (right to rectification),
                    </li>
                    <li>
                        Request, under certain conditions, deletion of information (right to erasure),
                    </li>
                    <li>
                        Request, under certain conditions, limitation of personal data processing (right to restriction of processing)
                    </li>
                    <li>
                        Object, under certain conditions, to processing by us (right to object), especially with regard to processing 
                        of data related to marketing (e.g. issue of newsletters),
                    </li>
                    <li>
                        Ask for the data you provide to us in a structured, commonly-used and machine-readable format (right to data portability), 
                        if this is considered to be technically feasible.
                    </li>
                    <li>
                        In case of a data breach which may put your rights and freedoms at high risk, if this does not fall under one of the 
                        exceptions provided for in the GDPR, the Company undertakes to inform you of the breach without unjustifiable delay
                    </li>
                </ol>
                <p>
                    Compliance with the legal framework for data processing and, in this context, the exercise of your rights is a priority for us. 
                    For this reason, we have the right to request the provision of additional information necessary to confirm your identity, 
                    before you exercise your rights.
                    In principle, the Company is required to respond to your request immediately, and within one month at the latest. 
                    If deemed necessary, taking into account the number and complexity of the request(s), this period may be extended by a 
                    further two months. In each case we will notify you as soon as possible, and always within one month of submitting your request, 
                    regarding the progress of your request and the reason for any potential delay in the satisfactory resolution of your issue.
                </p>
                <p>
                    In the event that your claims are clearly unfounded or excessive, in particular because of recurrence, the Company 
                    may either impose a reasonable fee, taking into account the administrative costs of providing the information or 
                    communicating or executing the requested action, or refuse to follow up on your request.
                    If you believe that we are not in compliance with data protection legislation, you have the right to submit a 
                    complaint to the Greek Data Protection
                    Authority (www.dpa.gr).
                </p>
                <h4>7. Hyperlinks to third party websites</h4>
                <p>
                    Access to third party websites is provided with the assistance of appropriate hyperlinks on the Site. 
                    The placement of these hyperlinks has been done for the sole purpose of facilitating visitors while browsing 
                    the internet. It is in no way an indication of the acceptance or approval of the contents of the websites shown by a hyperlink.
                    Access using the links provided on this site its done solely at your own discretion and we encourage you 
                    to read the data protection policy of each website you visit carefully.
                </p>
                <h4>
                8. Social Media
                </h4>
                <h5>
                Facebook
                </h5>
                <p>
                    The Company maintains an official Facebook page (https://www.facebook.com/Netourwork-1824620037609043/). 
                    You can contact us through our Facebook page to get more information about our services via the ‘send message’ 
                    facility. In order to respond to your queries, we process your Facebook username and other information publicly 
                    available through your profile (e.g. your email address). Sending a message for the purposes of communication 
                    between us implies your consent to the abovementioned processing of your data. Access to and use of the webpage 
                    is subject to this Personal Data Protection Policy.
                    In the event that you choose to click “LIKE” on our page, this means that you give your consent to view news and 
                    promotions (via the newsfeed) made by the Company through its Facebook page. If you do not wish to receive such 
                    updates, you can click “UNLIKE” at any time.
                    Facebook Inc., 1601 S California Ave, Palo Alto, CA 94304, USA and Facebook Ireland Limited, Hanover Reach, 5-7 
                    Hanover Quay, Dublin, Ireland, are responsible for Facebook’s operations in the European Union. You can learn about 
                    the processing of your Facebook data via the following links:<br />
                    <a href="https://el-gr.facebook.com/policy.php?CAT_VISITOR_SESSION=c7b73ebc78d1681ade25473632eae199" target="_blank">
                    https://el-gr.facebook.com/policy.php?CAT_VISITOR_SESSION=c7b73ebc78d1681ade25473632eae199
                    </a><br />
                    <a href="https://el-gr.facebook.com/business/GDPR" target="_blank">https://el-gr.facebook.com/business/GDPR</a>
                </p>
                <h5>Instagram</h5>
                <p>
                    The Company maintains an official Instagram account (https://www.instagram.com/netourwork/). Through the 
                    Instagram platform you can follow the Company’s account and comment on its posts, thus providing data to 
                    be processed on the platform. Instagram, which belongs to Facebook Inc., 1601 S California Ave, Palo Alto, 
                    CA 94304, USA, and Facebook Ireland Limited, Hanover Reach, 5-7 Hanover Quay, Dublin, Ireland, has its own 
                    cookie and data protection policies, over which we exercise no control and which we are not in a position 
                    to influence. Access to Instagram is solely at your own risk, and we encourage you to read its Data Protection 
                    Policy carefully since you are accepting its terms by using its services.
                </p>
                <h5>Twitter</h5>
                <p>
                    The Company maintains an official Twitter account (https://twitter.com/netourwork). Through the Twitter platform, 
                    you can follow the Company’s account, retweet and comment on its posts, thus providing data to be processed on the 
                    platform. Twitter, which belongs to Twitter, Inc., 1355 Market St, Suite 900, San Francisco, CA 94103, has its own 
                    cookie and data protection policies over which we exercise no control and which we are not in a position to influence. 
                    Access to Twitter is solely at your own risk and we encourage you to read Twitter’s data protection policy carefully, 
                    since you are accepting its terms by using its services.
                </p>
                <h5>YouTube</h5>
                <p>
                    The Company maintains an official YouTube channel (https://www.youtube.com/channel/UCgzRn36s1FapYM08MJ2bkDw). 
                    Through the YouTube platform, you can follow the Company’s account, watch its video posts and comment on them, 
                    thus providing data to be processed on the platform. YouTube, which belongs to Google Inc., 1600 Amphitheatre Pkwy, 
                    Mountain View, CA 94043-1351, USA, has its own cookie and data protection policies over which we exercise no control 
                    and which we are not in a position to influence. Access to YouTube is solely at your own risk, and we encourage you 
                    to read Google’s Data Protection Policy carefully since you are accepting its terms by using its services
                </p>
                <h5>General information regarding social media</h5>
                <p>
                    The Company takes all appropriate technical and organisational measures to ensure the security of data processing via 
                    social networking, including, but not limited to, restricting the number of persons with administrator-level access 
                    to each page. The Company is only responsible for the method and means by which it processes your data for its own 
                    purposes (communication, information and promotions) and to the extent that it exercises control over your data. 
                    On the other hand, it bears no responsibility for the method or means that any social networking platform uses 
                    to process your data.
                    In all cases, we urge you to be particularly careful about the content you post on our social media platform pages, 
                    especially when you provide personal or third party personal information, and you must make sure that the page you 
                    are communicating with is indeed the official page.
                </p>
                <h5>
                    Comments on Social Media
                </h5>
                <p>
                    We encourage users to comment on posts and/or on pages they maintain on social media, in the context of an open dialogue 
                    with respect for differing opinions. We do not have a general obligation to review the content submitted by users of 
                    these social media platforms, although it does work to ensure a secure online environment.
                </p>
                <p>
                    Accordingly, the Site administrators reserve the right to remove any kind of content found to be in violation 
                    of its terms of use, such as content that is abusive, vulgar, pornographic, threatening, constitutes advertising, 
                    or infringes intellectual property rights or contains a false statement about the user, and at the same time it 
                    reserves the right to block users who submit such material. Should you believe that user content hosted on Company 
                    pages on social media platforms affects or otherwise violates our terms of use, please contact the administrators directly.
                </p>
                <h4>
                    9. Minors
                </h4>
                <p>
                    The Company operates its services exclusively for persons over 18 years of age. In the case of submission of orders, 
                    the user/visitor is presumed to be over 18 years of age or, if they are under 18, they are presumed to have obtained 
                    the necessary consent from the person having parental responsibility, and it is also presumed that said person’s 
                    information will be provided if requested by the Company.
                </p>
                <p>
                    Since it is not technically feasible to effectively control the age of the visitors/users of the Site, 
                    we are committed to deleting all relevant information if the submission of personal data relating to 
                    minors is reported. This deletion is without prejudice to the need to keep the data in the event of 
                    provision of grounds for, or exercise or support of our legal claims or the fulfillment of a legal obligation.
                </p>
                <h4>
                    10. Changes in policy and updating of information
                </h4>
                <p>
                    This policy may be changed at any time and without prior notice. Guided by the principle of transparency, 
                    we are committed to notifying you of any major changes in our policy. In any case, however, you should periodically 
                    review our policy, since the use of our services implies acceptance of its terms by you.
                </p>
            </Card>
        </Container>
    </Fragment>
}

export default Privacy;