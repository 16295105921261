import React, { useState, useEffect, Fragment } from 'react';
import { AuthService } from '../../Services/AuthService';
import { EventService } from '../../Services/EventService';
import { Redirect } from 'react-router';
import { Row, Col, Card, Form, FormGroup, Input, Label, Button, Alert, Table, CardBody, NavLink, Container } from 'reactstrap';
import { MyLibrary } from '../../Services/MyLibrary';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleDown, faEdit, faPlusSquare, faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import FileService from '../../Services/FileService';
import DateTimePicker from 'react-datetime-picker';
import useForceUpdate from 'use-force-update';
import axios from 'axios';


const Terms = () => {

    const [html, setHtml] = useState('');

    useEffect(() => {
        axios.get('/home.html' ).then(r => {
            console.log(r.data);
            setHtml(r.data)
        });
    }, [])

    return <Fragment >
        <Container>
            <Card>
                <h2 className="text-primary">DIGITAL EVENT TERMS OF USE</h2>
                <p>
                    The present terms of use govern your rights and obligations with respect to the use of services of the website 
                    at the web address www.digitalevent.gr, hereinafter referred to as Digital Event.
                    The Website is property of the company KAINOTOMES DIKTYOSEIS O.E. - NETOURWORK (unofficial translation: 
                    INNOVATION NETWORKS G.P.) hereinafter referred to as the Company.
                </p>
                <p>
                    <strong>Company details</strong><br />
                    Name: KAINOTOMES DIKTYOSEIS O.E. - NETOURWORK<br />
                    Headquarters: 17 Parou street, GR-15127 Melissia, Attica<br />
                    Telephone: +30 2108033473<br />
                    E-mail: info@netourwork.com
                </p>
                <p>
                    <strong>Binding Effect</strong><br />
                    By entering and navigating in the Website as a visitor/user you declare acceptance of the present terms of use. 
                    Such acceptance consists in agreeing explicitly, unconditionally and irrevocably to comply with the present terms. 
                    In the event that a user/visitor disagrees with any of the terms, he/she must cease and abstain from browsing into Digital Event.<br />
                    Digital Event reserves the right to modify the terms of use at any time and without justification, while any amendments 
                    can only be considered if they are in writing and incorporated in the present document. Users are therefore advised to 
                    regularly review the content of the present terms. In the event that any specific terms are no more applicable or 
                    become invalid in full or partially, the validity of the remaining terms shall not be affected.
                </p>
                <p>
                    <strong>Description of Services</strong><br />
                    Our company offers a wide range of services for private companies, public bodies and any other types of legal entities, 
                    that wish to obtain innovative services in the field of tourism. Digital Event is an online platform which, in partnership 
                    with other organizations, such as tour operators, travel agencies, local state organizations, airlines, travel editors and 
                    tourism specialists, offers:
                </p>
                <ul>
                    <li>
                        Vreminars (Virtual Reality Webinars) & Webinars,
                    </li>
                    <li>
                        Virtual &amp; Digital Specialist Training
                    </li>
                    <li>
                        Virtual &amp; Digital Exhibitions
                    </li>
                    <li>
                        Digital B2B Meetings
                    </li>
                    <li>
                        Virtual &amp; Digital Contests
                    </li>
                    <li>
                        Virtual &amp; Digital School
                    </li>
                    <li>
                        Newsletter Campaigns, and
                    </li>
                    <li>
                        Targeted Online Advertisements
                    </li>
                </ul>
                <p>
                    <strong>Intellectual and Industrial Property Rights</strong><br />
                    You hereby acknowledge that all contents of Digital Event (texts, trademarks, images, services, electronic files, etc.) 
                    are intellectual property of the Company since their publication on the internet, and therefore they are protected by the 
                    relevant legal provisions of the Greek and European laws and international conventions. The domain name www.digitalevent.gr 
                    is legally trademarked by the Company, as well as the legally registered trademark, and therefore these are also protected 
                    by the relevant legal provisions.
                </p>
                <p>
                    <strong>User Responsibility</strong><br />
                    User hereby undertakes to abide by the rules of ethical conduct (Netiquette). In such cases where Digital Event enables 
                    users to post or send by private message or in any other way whatsoever any information, audio-visual material, text, 
                    link to a website or generally any file that is readable/executable by a computer or any other electronic device, 
                    to any other user or member of Digital Event, the users shall be solely responsible for the content of this materials. 
                    It shall be reminded that for each publication/sending it is necessary to have the right to transmit the specific 
                    content either subject to the applicable legislation or to any legal contractual relationship that binds the user. 
                    In any case, any content of the below mentioned types shall be prohibited:<br />
                    Illegal, abusive, vulgar, pornographic, threatening, defamatory, annoying, harmful to minors or expressing nationalistic, 
                    racial or other discriminatory views,<br />
                    Violating intellectual or other proprietary rights of third parties, including trademarks and trade secrets as well as patents,<br />
                    Containing any virus, malicious software or code (malware), unsolicited promotional material (pop-up) and unsolicited 
                    bulk mailing (spam) that may cause either temporary or permanent damage/malfunction in any equipment (hardware or software) 
                    of computers and electronic devices in general, or delay, interruption and termination in the operation of servers or 
                    any telecommunications network,<br />
                    Containing false statement concerning the user’s identity or imitation of any other entity (natural or legal).<br />
                    The users of Digital Event are required to abstain from using the site for the purpose of carrying out acts that may 
                    result in the prosecution or the commencement of any civil or administrative proceedings against the Company for acts 
                    that are indicatively and without limitation referred to in the Penal Code, the Specific Penal Laws, the Personal 
                    Data Protection Legislation, the Telecommunications Law, as well as other relevant laws of the European Union, 
                    the National Telecommunications Commission, the Personal Data Privacy Authority and any other Public or Administrative 
                    Authority and Service, as well as for acts that could affect any rights or other legitimate interests of the Company 
                    or any third party.<br />
                    In the event that any third party files a legal application against the Company for breach of any of their legal rights, 
                    which falls into those set out in the terms of use in the scope of user’s responsibility, the Company reserves the right 
                    to seek concourse against the user. Along with any claims of third parties, the Company reserves the right to claim 
                    compensation by the user for breach of the terms of use and for any legal consequence arising therefrom.<br />
                    In any case, by accessing and using Digital Event, you agree that any exclusions and limitations of liability set out in 
                    the present document are reasonable and proportionate.<br />
                    Digital Event reserves the right to modify and/or temporarily or permanently interrupt part or all of its services with or 
                    without notice to the users/members, for a substantial cause. Additionally, Digital Event is entitled to deny or restrict 
                    access to the material posted to it if it believes that this material violates the present terms of use.
                </p>
                <p>
                    <strong>Limitation of Liability</strong><br />
                    Digital Event is making every effort to constantly develop, update, and improve its services. All necessary organizing and 
                    safeguarding measures are taken and the most appropriate technical means for the protection of content are applied, aiming 
                    at providing the user with the safest possible environment in accordance with the relevant legal provisions. However, it 
                    is always possible that errors/malfunctions/interruptions may occur in the content of the site and/or that viruses or other 
                    malicious software may appear on the website or on its server.<br />
                    The Company shall not be held responsible for any damage and/or any other consequence that you may suffer resulting from your 
                    access to Digital Event and/or the use of its services, which are provided “as such”. No warranty is given on the part of the 
                    Company that its services will be provided without interruptions or errors or that these errors will be immediately restored.<br />
                    The content and information provided through the services, including the content of presentations and seminars, are produced 
                    by partner companies, public bodies or other legal entities, which bear the responsibility for their completeness and for 
                    ensuring their legitimacy, including the non-violation of intellectual property and personal data protection rights.<br />
                    Under no circumstances will be provided any warranty to users on behalf of the Company for accuracy, completeness and 
                    availability of the content presented and the services provided by Digital Event.
                </p>
                <p>
                    <strong>Applicable Law and Miscellaneous Provisions</strong><br />
                    The present terms of use are governed and supplemented where necessary by the Greek and European law, while the courts of the 
                    city of Athens shall be competent for any disputes.<br /><br />

                    The Company seeks to resolve amicably and extra-judicially any dispute which may arise in the course of use of Digital Event 
                    and/or the application of the present terms of use.<br /><br />

                    In case that you as a user/member encounter any faulty item in Digital Event’s content or services, please notify the administrators 
                    directly by email to info@netourwork.com. 
                </p>
            </Card>
        </Container>
    </Fragment>
}

export default Terms;