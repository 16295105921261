import React,  { useState } from 'react';
import { Container, Input, Row, Col, Button, Form, FormGroup, Card, CardBody, NavLink } from 'reactstrap';
import './RegisterComponent.css';
import { AuthService } from '../../Services/AuthService';
import { useHistory } from 'react-router';
import { Redirect } from 'react-router-dom';

const RegisterComponent = () => {
    const authService = new AuthService();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    const [name, setName] = useState('');
    const [bio, setBio] = useState('-');
    const role = 'Participant';
    const roleInCompany = "-";
    const history = useHistory();
    const [user, setUser] = useState(localStorage.getItem('user') ? JSON.parse(localStorage.getItem('digitalEventUser')) : '');

    const submitRegister = (e) => {
        e.preventDefault();
        //console.log('submitted: ' + email + '-' + password);
        authService.register(email, password, name, bio, role,roleInCompany, 2).then(registerData =>{
            history.push('/networking/login');
        }).catch(e => {
            console.log(e);
        });
    };
    if(user){
        return <Redirect to="/networking" />
    }
    return (
        <Container fluid className="loginPage">
            <Row className="">
                <Col md="4" >
                        
                </Col>
                <Col md="4" >
                    <div>
                        <Card>
                            <CardBody>
                                <Form onSubmit={submitRegister}>
                                    <FormGroup>
                                        <Input type='email' className="mycustomInput" placeholder="Email"
                                            onChange={(e) => { setEmail(e.target.value) }} />
                                    </FormGroup>
                                    <br />
                                    <FormGroup>
                                        <Input type='password' placeholder="Password" className="mycustomInput" 
                                            onChange={(e) => { setPassword(e.target.value) }} />
                                    </FormGroup>
                                    <br />
                                    <FormGroup>
                                        <Input type='password' placeholder="Repeat Password" className="mycustomInput" 
                                            onChange={(e) => { setRepeatPassword(e.target.value) }} />
                                    </FormGroup>
                                    <br />
                                    <FormGroup>
                                        <Input type='text' placeholder="Name" className="mycustomInput" 
                                            onChange={(e) => { setName(e.target.value) }} />
                                    </FormGroup>
                                    
                                    <br />
                                    <div className="d-grid gap-2">
                                        <Button color="primary" className="myCustomButton" size="sm" block>Register</Button>
                                    </div>
                                    
                                </Form>
                                    <div className="d-grid gap-2">
                                        <NavLink className="btn btn-link btn-sm" to="/networking/login">Login</NavLink>
                                    </div>
                            </CardBody>
                        </Card>
                        
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default RegisterComponent;