import React,  { useState, useProps } from 'react';
import { Container, Input, Row, Col, Button, Form, FormGroup, Card, CardBody, NavLink } from 'reactstrap';
import './CreateUserComponent.css';
import { AuthService } from '../../Services/AuthService';
import { useHistory } from 'react-router';
import { Redirect } from 'react-router-dom';

const CreateUserComponent = (props) => {
    const authService = new AuthService();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    const [name, setName] = useState('');
    const [bio, setBio] = useState('-');
    const role = 'Participant';
    const roleInCompany = "-";
    const history = useHistory();
    const companyId = props.companyId;
    const user = JSON.parse(localStorage.getItem('digitalEventUser'));

    const submitRegister = (e) => {
        e.preventDefault();
        //console.log('submitted: ' + email + '-' + password);
        authService.register(email, password, name, bio, role,roleInCompany).then(registerData =>{
            props.userCreated();
        }).catch(e => {
            console.log(e);
        });
    };

    return (
        <Container fluid >
            <div>
                <Card>
                    <CardBody>
                        <Form onSubmit={submitRegister}>
                            <FormGroup>
                                <Input type='email' className="mycustomInput" placeholder="Email"
                                    onChange={(e) => { setEmail(e.target.value) }} />
                            </FormGroup>
                            <br />
                            <FormGroup>
                                <Input type='password' placeholder="Password" className="mycustomInput" 
                                    onChange={(e) => { setPassword(e.target.value) }} />
                            </FormGroup>
                            <br />
                            <FormGroup>
                                <Input type='password' placeholder="Repeat Password" className="mycustomInput" 
                                    onChange={(e) => { setRepeatPassword(e.target.value) }} />
                            </FormGroup>
                            <br />
                            <FormGroup>
                                <Input type='text' placeholder="Name" className="mycustomInput" 
                                    onChange={(e) => { setName(e.target.value) }} />
                            </FormGroup>
                            
                            <br />
                            <div className="d-grid gap-2">
                                <Button color="primary" className="myCustomButton" size="sm" block>Create User</Button>
                            </div>
                            
                        </Form>
                    </CardBody>
                </Card>
                
            </div>
        </Container>
    );
};

export default CreateUserComponent;