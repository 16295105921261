import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Container, Row, Col, Navbar, NavbarToggler, NavbarBrand, 
    Collapse, Nav, NavItem, NavLink, UncontrolledDropdown, DropdownToggle, 
    DropdownMenu, DropdownItem, NavbarText, Form, Input } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../App.css';
import "typeface-roboto";
import { useHistory } from 'react-router';
import { faBell, faUser } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AuthService } from '../../Services/AuthService';
import { EventService } from '../../Services/EventService';
import FileService from '../../Services/FileService';

const TopBar = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const { user, setUser } = props;
    const [activeMenu, setActiveMenu] = useState(window.location.pathname);
    const [event, setEvent] = useState({});
    const [notifications, setNotifications] = useState([]);
    const authService = new AuthService();
    const eventService = new EventService();
    const fileService = new FileService();
    const history = useHistory();

    useEffect(() => {
        eventService.getEvent(1).then(r => setEvent(r)).catch(e => setErrorMessage(e));
        if(user && user.userId){
            authService.whoami().then(r => {
                if(r.userId){
                    fetchNotifications();
                    setInterval(fetchNotifications, 10000)
                }
                else{
                    logout();
                }
            }).catch(e => {
                logout();
            })
        }
        
      }, [])
    
      const fetchNotifications = () => {
        if(user){
          authService.getNotifications().then(r => setNotifications(r));
        }
      }
    
      const clearNotifications = () => {
        authService.readAllNotifications().then(r1 => authService.getNotifications().then(r => setNotifications(r)))
      };
    
      const readNotification = (notificationId) => {
        authService.readNotification(notificationId).then(r1 => authService.getNotifications().then(r => setNotifications(r)));
      }
    
      const toggle = () => {
        setIsOpen(!isOpen);
      };
    
      const logout = () => {
        localStorage.setItem('digitalEventUser', null);
        authService.logout().then(r => {
          setUser(null);
          history.push('/');
        }).catch(e => console.log(e));
        
      }

    return <Navbar className="mainMenu align-middle" expand="md">
        <NavbarBrand href="/"><img src={(event && event.logo) ? fileService.getUrl(JSON.parse(event.logo)[0].name) : "/logo.png"} alt="logo" style={{ height: '60px' }}/></NavbarBrand>
        <NavbarToggler onClick={toggle} style={{color: '#000'}} >Menu</NavbarToggler>
        <Collapse isOpen={isOpen} navbar style={{minHeight: '70px'}}>
        <Nav  navbar>
            <NavItem>
            <NavLink className={activeMenu === '/' ? 'active' : ''} onClick={() => setActiveMenu('/')} href="/" >Home</NavLink>
            </NavItem>
            <NavItem>
            <NavLink className={activeMenu === '/stage' ? 'active' : ''} onClick={() => setActiveMenu('/stage')} href="/stage">Stage</NavLink>
            </NavItem>
            <NavItem>
            <NavLink className={activeMenu === '/agenda' ? 'active' : ''} onClick={() => setActiveMenu('/agenda')} href="/agenda">Agenda</NavLink>
            </NavItem>
            <NavItem>
            <NavLink className={activeMenu === '/expo' ? 'active' : ''} onClick={() => setActiveMenu('/expo')} href="/expo">Suppliers</NavLink>
            </NavItem>
            <NavItem>
            <NavLink className={activeMenu.includes('/networking') ? 'active' : ''} onClick={() => setActiveMenu('/networking')} href="/networking">Networking</NavLink>
            </NavItem>
        </Nav>
        <Nav navbar className="ms-auto align-middle" style={{ minHeight: '70px' }}>
            {/* <Form className="align-middle" style={{paddingTop: '20px'}}>
            <Input type="search" className="mycustomSearch mt-auto mb-auto"/>
            </Form>
            <img src="/search.png" style={{ filter: 'brightness(0) invert(0.4)', marginLeft: '-30px', width: '15px', height: '15px', marginTop: '30px' }} /> */}
            <UncontrolledDropdown nav inNavbar style={{ borderRight: '1px solid #b0b0b0' }} >
            <DropdownToggle nav  style={{paddingTop: '25px'}} onClick={fetchNotifications}>
                <FontAwesomeIcon icon={faBell}  style={{ fontSize: '25px', color: notifications.length > 0 ? '#f00' : '#b0b0b0' }} />
            </DropdownToggle>
            <DropdownMenu right style={{width: '350px', }}>
                {notifications.length > 0 && notifications.map((n, i) => <><DropdownItem key={n.notificationId} onClick={() => {
                    readNotification(n.notificationId);
                    history.push('/networking/profile');
                }} style={{whiteSpace: 'normal'}}>
                <b>{n.title}</b><br />
                {n.text}
                </DropdownItem>
                </>)}
                <DropdownItem divider />
                <DropdownItem onClick={clearNotifications}>
                Clear All
                </DropdownItem>
            </DropdownMenu>
            </UncontrolledDropdown>
            {
            user && user.userId && 
                <UncontrolledDropdown nav inNavbar >
                <DropdownToggle nav >
                    {user.name} &nbsp;&nbsp;
                    <img src={user.photo ? fileService.getUrl(JSON.parse(user.photo)[0].name) : "/pic.png"} alt="profile" style={{ maxHeight: '60px', maxWidth: '60px' }} />
                </DropdownToggle>
                <DropdownMenu right style={{width: '350px', }}>
                    <DropdownItem>
                    <NavLink href="/networking/profile">
                        Profile
                    </NavLink>
                    </DropdownItem>
                    {
                    user.role === 'admin' && <DropdownItem>
                        <NavLink href="/manage">
                        Admin
                        </NavLink>
                    </DropdownItem>
                    }
                    {
                    user.roleInCompany === 'Administrator' && <DropdownItem>
                        <NavLink href="/managebooth">
                        Manage My Booth
                        </NavLink>
                    </DropdownItem>
                    }
                    <DropdownItem divider />
                    <DropdownItem onClick={logout}>
                    Logout
                    </DropdownItem>
                </DropdownMenu>
                </UncontrolledDropdown>
                
            }
            {
            (!user || !user.userId) &&
            <UncontrolledDropdown nav inNavbar >
                <DropdownToggle nav >
                <NavItem nav style={{paddingTop: '18px'}}>
                    <FontAwesomeIcon icon={faUser}  style={{ fontSize: '23px', color: '#b0b0b0' }} />
                </NavItem>
                </DropdownToggle>
                <DropdownMenu left style={{ marginTop: '20px'}}>
                <DropdownItem>
                    <NavLink href="/networking/login">Login</NavLink>
                </DropdownItem>
                <DropdownItem>
                    <NavLink href="/networking/register">Register</NavLink>
                </DropdownItem>
                </DropdownMenu>
            </UncontrolledDropdown>
            }
        </Nav>
        </Collapse>
    </Navbar>;

}

export default TopBar;