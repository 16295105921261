import React, { useState, useEffect } from 'react';
import FileService from '../../Services/FileService';
import { EventService } from '../../Services/EventService';
import { Col, Row, Form, FormGroup, Label, Input, Button, Card, Alert, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faBookmark, faPlusSquare, faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { AuthService } from '../../Services/AuthService';
import CreateUserComponent from '../CreateUserComponent/CreateUserComponent';
import { useHistory, useParams } from 'react-router';
import Quill from 'react-quill';


const ManageBoothAdmin = () => {
    const [company, setCompany] = useState({});
    const [event, setEvent] = useState({});
    const [successMessage, setSuccessMessage] = useState('');
    const [showAddUserModal, setShowAddUserModal] = useState(false);
    const { companyId } = useParams();
    const [showAddYoutubeModal, setShowAddYoutubeModal] = useState(false);
    const [youtubeLink, setYoutubeLink] = useState('');
    const user = JSON.parse(localStorage.getItem('digitalEventUser'));

    const fileService = new FileService();
    const authService = new AuthService();
    const eventService = new EventService();
    const history = useHistory();

    const toggleAddUserModal = () => {
        setShowAddUserModal(!showAddUserModal);
    }

    const toggleAddYoutubeModal = () => {
        setShowAddYoutubeModal(!showAddYoutubeModal);
    }

    useEffect(() => {
        if(user && user.role === 'admin'){
            authService.getCompany(companyId).then(r => {
                setCompany(r);
            });
            eventService.getEvent(1).then(r => setEvent(r));
        }
        else{
            history.push('/networking/login');
        }
    }, []);

    const saveCompany = (e) => {
        e.preventDefault();
        authService.saveCompany(company).then(r => {
            setSuccessMessage('The changes have been saved successfully');
            setTimeout(() => {
                setSuccessMessage('');
            }, 3000);
        })
    };
    
    const uploadCompanyLogo = (file) => {
        const uploadPromise = new Promise((resolve, reject) => {
            fileService.uploadFile(file).then(r => {
                let existingImages;
                existingImages = r;
                setCompany({
                    ...company,
                    logo: existingImages
                });
                resolve(true);
            });
        });
        return uploadPromise;
    };

    const uploadCompanyMainImage = (file) => {
        const uploadPromise = new Promise((resolve, reject) => {
            fileService.uploadFile(file).then(r => {
                let existingImages;
                existingImages = r;
                setCompany({
                    ...company,
                    mainImage: existingImages
                });
                resolve(true);
            });
        });
        return uploadPromise;
    };

    const uploadCompanyImages = (file) => {
        const uploadPromise = new Promise((resolve, reject) => {
            fileService.uploadFile(file).then(r => {
                let existingImages;
                    if(company.images){
                        console.log(JSON.parse(r));
                        existingImages = JSON.parse(company.images);
                        existingImages.push(JSON.parse(r)[0]);
                        setCompany({
                            ...company,
                            images: JSON.stringify(existingImages)
                        });
                    }
                    else{
                        existingImages = r;
                        setCompany({
                            ...company,
                            images: existingImages
                        });
                    }
            });
        });
        return uploadPromise;
    };

    const userCreated = () => {
        authService.getCompany(user.companyId).then(r => {
            setCompany(r);
        });
        setShowAddUserModal(false);
    };

    const addYoutubeVideo = () => {
        let newVideo = {
            youtubeLink: youtubeLink.replace('https://www.youtube.com/watch?v=', 'https://www.youtube.com/embed/'),
        };
        let existingImages;
        if(company.images){
            existingImages = JSON.parse(company.images);
            existingImages.push(newVideo);
            setCompany({
                ...company,
                images: JSON.stringify(existingImages)
            });
        }
        else{
            existingImages = JSON.stringify([newVideo]);
            setCompany({
                ...company,
                images: existingImages
            });
        }
        setShowAddYoutubeModal(false);
    };

    return <>
        <Card style={{margin: '20px'}}>
            {
                successMessage && <Alert color="success">{successMessage}</Alert>
            }
            <Form onSubmit={saveCompany} >
                <Row>
                    <Col md="12" className="text-end">
                        <Button color="primary" size="sm"><FontAwesomeIcon icon={faSave} /> Save </Button>
                    </Col>
                    <Col md="6">
                        <FormGroup>
                            <Label>Company Name</Label>
                            <Input type="text" defaultValue={company.title} onChange={(e) => setCompany({...company, title: e.target.value})} />
                        </FormGroup>
                    </Col>
                    <Col md="6">
                        <FormGroup>
                            <Label>Activity Sector</Label>
                            <Input type="select" value={company.activity} onChange={(e) => setCompany({...company, activity: e.target.value})}>
                                
                                <option value="">Select Activity Sector</option>
                                {
                                    event.companyActivities && JSON.parse(event.companyActivities).map((ca, i) => {
                                        return <option value={ca.name}>{ca.name}</option>
                                    })
                                }
                            </Input>
                        </FormGroup>
                    </Col>
                    
                    <Col md="6">
                        <FormGroup>
                            <Label>City</Label>
                            <Input type="text" defaultValue={company.city} onChange={(e) => setCompany({...company, city: e.target.value})} />
                        </FormGroup>
                    </Col>
                    <Col md="6">
                        <FormGroup>
                            <Label>Country</Label>
                            <Input type="text" defaultValue={company.country} onChange={(e) => setCompany({...company, country: e.target.value})} />
                        </FormGroup>
                    </Col>
                    <Col md="4">
                        <FormGroup>
                            <Label>Website</Label>
                            <Input type="text" defaultValue={company.address} onChange={(e) => setCompany({...company, address: e.target.value})} />
                        </FormGroup>
                    </Col>
                    <Col md="4">
                        <FormGroup>
                            <Label>Email</Label>
                            <Input type="email" defaultValue={company.email} onChange={(e) => setCompany({...company, email: e.target.value})} />
                        </FormGroup>
                    </Col>
                    <Col md="4">
                        <FormGroup>
                            <Label>Phone</Label>
                            <Input type="text" defaultValue={company.phone} onChange={(e) => setCompany({...company, phone: e.target.value})} />
                        </FormGroup>
                    </Col>
                    <Col md="12">
                        <FormGroup>
                            <Label>Description</Label>
                            <Quill theme="snow" value={company.description} onChange={(v) => setCompany({...company, description: v})} />
                        </FormGroup>
                    </Col>
                    <Col md="12">
                        <hr />
                        <FormGroup>
                            <Label>Logo</Label><br />
                            <Input type="file" onChange={(e) => uploadCompanyLogo(e.target.files[0])} />
                            {
                                company.logo && <img src={fileService.getUrl(JSON.parse(company.logo)[0].name)} style={{width: '150px'}} alt="logo"/>
                            }
                        </FormGroup>
                        <hr />
                    </Col>
                    <Col md="6">
                        <FormGroup>
                            <Label>Main Image</Label><br />
                            <Input type="file" onChange={(e) => uploadCompanyMainImage(e.target.files[0])} />
                            {
                                company.mainImage && <img src={fileService.getUrl(JSON.parse(company.mainImage)[0].name)} style={{width: '100%'}} alt="main image"/>
                            }
                        </FormGroup>
                    </Col>
                    <Col md="6">
                        <FormGroup>
                            <Button className="float-end" color="danger" size="sm" onClick={toggleAddYoutubeModal}>Add Youtube Video</Button>
                            <Label>Images</Label><br />
                            <Input type="file" onChange={(e) => uploadCompanyImages(e.target.files[0])} /><br />
                        </FormGroup>
                        <Row>
                        {
                            company.images && JSON.parse(company.images).map((i, index) => {
                                if(i.name){
                                    return <Col md="3"><Card><img key={index} src={fileService.getUrl(i.name)} 
                                    style={{maxWidth: '100%', width: 'auto!important', height: '70px'}} alt="logo"/><br />
                                    <Button color="danger" size="sm" onClick={() => {
                                        let images = JSON.parse(company.images);
                                        images.splice(index, 1);
                                        setCompany({...company, images: JSON.stringify(images)});
                                    }}><FontAwesomeIcon icon={faTimesCircle}/></Button></Card>
                                    </Col>
                                }
                                else if(i.youtubeLink){
                                    return <Col md="3">
                                        <Card><iframe key={index} width="100px" height="70" src={i.youtubeLink} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe><br />
                                    <Button color="danger" size="sm" onClick={() => {
                                        let images = JSON.parse(company.images);
                                        images.splice(index, 1);
                                        setCompany({...company, images: JSON.stringify(images)});
                                    }}><FontAwesomeIcon icon={faTimesCircle}/></Button></Card></Col>
                                }
                            })
                        }
                        </Row>
                    </Col>
                </Row>
            </Form>
            <hr />
            <h3 className="text-primary">Users</h3>
            <Row>
                {
                    company && company.users && company.users.map(p => {
                        return <Col md="3" key={p.userId}>
                            <Card>
                                <Row>
                                    <Col md="3">
                                        <img src="/pic.png" alt="profile" style={{ height: '60px' }} />
                                    </Col>
                                    <Col md="9">
                                        <span className="float-end text-primary" style={{ fontSize: '25px' }}><FontAwesomeIcon icon={faBookmark} /></span>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: '5px' }}>
                                    <Col md="12">
                                        <h6>{p.name}</h6>
                                        <p>{p.roleInCompany}</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="12">
                                        {
                                            user && user.userId !== p.userId && <>
                                                <Button onClick={() => arrangeAppointment(p.userId)} color="warning" size="sm" style={{ marginRight: "10px" }}>Meet</Button>
                                                <Button size="sm" color="primary" onClick={() => history.push(`/networking/profile/${p.userId}`)}>View Profile</Button>
                                            </>
                                        }
                                        
                                    </Col>
                                </Row>
                            </Card>
                            
                        </Col>
                    })
                }
                <Col md="3" >
                    <Card className="text-primary btn" onClick={toggleAddUserModal}>
                        <Row>
                            <Col md="12" className="text-center">
                                <FontAwesomeIcon icon={faPlusSquare} style={{fontSize: '40px'}} />
                            </Col>
                            
                        </Row>
                        <Row style={{ marginTop: '5px' }}>
                            <Col md="12">
                                <h2 className="text-center">Add User</h2>        
                            </Col>
                        </Row>
                        
                    </Card>
                    
                </Col>
            </Row>
        </Card>
        <Modal isOpen={showAddUserModal} toggle={toggleAddUserModal} >
            <ModalHeader toggle={toggleAddUserModal}>Create User</ModalHeader>
            <ModalBody>
                <CreateUserComponent userCreated={userCreated} companyId={companyId}/>
            </ModalBody>
            <ModalFooter>
                <Button color="danger" onClick={toggleAddUserModal}>Close</Button>
            </ModalFooter>
        </Modal>
        <Modal isOpen={showAddYoutubeModal} toggle={toggleAddYoutubeModal} >
            <ModalHeader toggle={toggleAddYoutubeModal}>Insert the link from youtube</ModalHeader>
            <ModalBody>
                <Input type="url" placeholder="Youtube Link" onChange={(e) => setYoutubeLink(e.target.value)} />
            </ModalBody>
            <ModalFooter>
                <Button type="button" color="primary" size="sm" onClick={addYoutubeVideo}>Add</Button>
                <Button color="danger" onClick={toggleAddUserModal}>Close</Button>
            </ModalFooter>
        </Modal>
    </>
};

export default ManageBoothAdmin;