import BaseService from './BaseService';

export class AuthService extends BaseService{

    login(email, password){
        const loginPromise = new Promise((resolve, reject) => {
            this.api.post('auth/login', {email, password}).then(response => {
                localStorage.setItem('digitalEventUser', JSON.stringify(response.data));
                resolve(response.data);
            }).catch(e => reject('Wrong email or password'));
        });
        return loginPromise;
    }

    whoami(){
        const whoamiPromise = new Promise((resolve, reject) => {
            this.api.get('auth/whoami').then(r => resolve(r.data)).catch(e => reject(e.data))
        });
        return whoamiPromise;
    }

    changePassword(userId, password){
        const changePasswordPromise = new Promise((resolve, reject) => {
            this.api.post('auth/changepassword', {userId, password}).then(r => resolve(true)).catch(e => reject(e));
        });
        return changePasswordPromise;
    }

    register(email, password, name, bio, role, roleInCompany, companyId){
        const registerPromise = new Promise((resolve, reject) => {
            this.api.post('auth/register', {email, password, name, bio, role, roleInCompany, companyId}).then(response => {
                resolve(response.data);
            }).catch(e => reject('Registration failed, check your data and try again'));
        });
        return registerPromise;
    }

    updateUser(user){
        const updateUserPromise = new Promise((resolve, reject) => {
            this.api.patch(`auth/${user.userId}`, user).then(r => resolve(r.data)).catch(e => reject(e.data));
        });
        return updateUserPromise;
    }

    deleteUser(userId){
        const deleteUserPromise = new Promise((resolve, reject) => {
            this.api.delete(`auth/${userId}`).then(r => resolve(r.data)).catch(e => reject(e.data));
        });
        return deleteUserPromise;
    }

    logout(){
        const logoutPromise = new Promise((resolve, reject) => {
            this.api.post('auth/logout', {}).then(r => resolve(true)).catch(e => reject(e.data));
        });
        return logoutPromise;
    }

    getCompanies(search){
        const companiesPromise = new Promise((resolve, reject) => {
            this.api.post('auth/companies', {search}).then(r => resolve(r.data)).catch(e => reject('Cannot get companies'));
        });
        return companiesPromise;
    }

    saveCompany(company){
        const saveCompanyPromise = new Promise((resolve, reject) => {
            if(company.companyId){
                this.api.patch(`auth/updatecompany/${company.companyId}`, company, {withCredentials: true})
                    .then(r => resolve(r.data)).catch(e => reject('Failed to update company'))
            }
            else{
                this.api.post('auth/createcompany', company)
                    .then(r => resolve(r.data)).catch(e => reject('Failed to update company'));
            }
        });
        return saveCompanyPromise;
    }

    initializeCompany(companyId){
        const initCompanyPromise = new Promise((resolve, reject) => {
            this.api.get(`auth/initcompany/${companyId}`).then(r => resolve(r.data)).catch(e => reject(e.data))
        });
        return initCompanyPromise;
    }

    getCompany(companyId){
        const companyPromise = new Promise((resolve, reject) => {
            this.api.get(`auth/companies/${companyId}`, {withCredentials: true}).then(r => resolve(r.data)).catch(e => reject(e));
        });
        return companyPromise;
    }

    deleteCompany(companyId){
        const deleteCompanyPromise = new Promise((resolve, reject) => {
            this.api.delete(`auth/deletecompany/${companyId}`, {withCredentials: true}).then(r => resolve(r.data)).catch(e => reject(e));
        });
        return deleteCompanyPromise;
    }

    getAdmins(){
        const getAdminsPromise = new Promise((resolve, reject) => {
            this.api.post('auth/admins').then(r => resolve(r.data)).catch(e => reject(e));
        });
        return getAdminsPromise;
    }

    getNotifications(){
        const notificationsPromise = new Promise((resolve, reject) => {
            this.api.post('auth/notifications', {}).then(r => resolve(r.data)).catch(e => reject(e));
        });
        return notificationsPromise;
    }

    readNotification(notificationId){
        const readPromise = new Promise((resolve, reject) => {
            this.api.post(`auth/readnotification`, {notificationId}).then(r => resolve(r)).catch(e => reject(e));
        });
        return readPromise;
    }

    readAllNotifications(){
        const readAllPromise = new Promise((resolve, reject) => {
            this.api.post('auth/readallnotifications', {}).then(r => resolve(r)).catch(e => reject(e));
        });
        return readAllPromise;
    }

    requestResetPassword(email){
        const rrpPromise = new Promise((resolve, reject) => {
            this.api.post('auth/resetpassword', {email}).then(r => resolve(r)).catch(e => reject(e));
        });
        return rrpPromise;
    }

    performResetPassword(code){
        const rpPromise = new Promise((resolve, reject) => {
            this.api.post('auth/doresetpassword', {code}).then(r => resolve(r)).catch(e => reject(e));
        });
        return rpPromise;
    }

}