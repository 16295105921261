import React, { useState, useEffect } from 'react';
import { AuthService } from '../../Services/AuthService';
import { EventService } from '../../Services/EventService';
import { Row, Col, Card, Button, Input } from 'reactstrap';
import FileService from '../../Services/FileService';
import { Redirect } from 'react-router';
import { useHistory } from 'react-router';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faClock, faEdit, faEnvelope, faSquare } from '@fortawesome/free-regular-svg-icons';
import timezones from 'timezones-list';

const Agenda = () => {
    const [event, setEvent] = useState({});
    const [sessions, setSessions] = useState([]);
    const user = JSON.parse(localStorage.getItem('digitalEventUser'));
    const [timezone, setTimezone] = useState('Europe/Athens');
    const [myTimezone, setMyTimezone] = useState('');
    const [showMyTimezone, setShowMyTimezone] = useState(false);
    const history = useHistory();

    const fileService = new FileService();
    const eventService = new EventService();

    useEffect(() => {
        //console.log(timezones)
        eventService.getSessions().then(r => setSessions(r));
        eventService.getEvent(1).then(r => {
            setEvent(r);
            if(r.timezone){
                setTimezone(r.timezone);
            }
            let eventData = JSON.parse(r.dataToComplete);
            if(user && user.data){
                let userData = JSON.parse(user.data);
                if(eventData.filter(dt => dt.type === 'Timezone').length > 0){
                    let dt = eventData.filter(dt => dt.type === 'Timezone')[0];
                    if(userData && userData[dt.name]){
                        setMyTimezone(userData[dt.name]);
                    }
                }
            }
        });
    }, []);

    const calcLocalTime = (time) => {
        const initTimezone = timezones.filter(t => t.tzCode === timezone)[0];
        const finalTimezone = timezones.filter(t => t.tzCode === myTimezone)[0];
        let initMargin = parseInt(initTimezone.utc.split(':')[0]);
        let finMargin = parseInt(finalTimezone.utc.split(':')[0]);
        let margin = initMargin - finMargin;
        let initTime = parseInt(time.split(':')[0]);
        let minutes = time.split(':')[1];
        return ((initTime + margin).toString() !== '24' ? (initTime + margin).toString() : '00') + ':' + minutes;
    }

    return <>
        <Row>

            <Col md="12" style={{paddingLeft: '30px', paddingRight:'15px'}}>   
                {   
                    myTimezone && !showMyTimezone && myTimezone !== timezone && <span className="text-primary float-end">
                        Hours are displayed in {timezone} timezone. <Button color="link" onClick={() => setShowMyTimezone(true)}>
                            Show in my timezone ({myTimezone})
                        </Button>
                    </span>
                }
                {   
                    myTimezone && showMyTimezone && myTimezone !== timezone && <span className="text-primary float-end">
                        Hours are displayed in {myTimezone} timezone. <Button color="link" onClick={() => setShowMyTimezone(false)}>
                            Show in default timezone ({timezone})
                        </Button>
                    </span>
                }
                <h3 className="text-primary">
                    Sessions
                </h3>
            </Col>
        </Row>
        
        <VerticalTimeline layout="2-columns">
            {timezone && !showMyTimezone && 
                sessions.sort((s1, s2) => s1.dateTime > s2.dateTime ? 1 : -1).map((s, i) => {
                    var date = new Date(s.dateTime);
                    var dt = date.toDateString('en-US', {timeZone: showMyTimezone ? myTimezone : timezone}) + ' ' + ( showMyTimezone ? calcLocalTime(date.getHours() + ":" + date.getMinutes()) : date.getHours() + ":" + date.getMinutes());
                    //s.dateTime.split('T')[0].split('-').reverse().join('-') + ' ' + s.dateTime.split('T')[1].substr(0, 5) + ', Duration: ' + s.duration + ' minutes'
                    return <VerticalTimelineElement key={i}
                        date={dt}
                        iconStyle={{ background: new Date(s.dateTime) < new Date(Date.now()) && new Date(s.dateTime) > new Date(Date.now() - (1000 * 60 * s.duration)) ? '#0093ff' : '#bbb', color: '#fff', paddingLeft: '10px' }}
                        icon={<FontAwesomeIcon icon={faCalendar} />}>
                        <h3 className="vertical-timeline-element-title">{s.title}</h3>
                        <div dangerouslySetInnerHTML={{__html: s.description}}></div>
                        <hr />
                        <Row>
                            <Col md="6">
                                <a className="btn btn-danger btn-sm" style={{width: '100%'}}
                                    href={s.url} target="_blank">Watch Online</a>
                            </Col>
                            <Col md="6">
                                {
                                    new Date(s.dateTime) < new Date(Date.now()) && new Date(s.dateTime) > new Date(Date.now() - (1000 * 60 * s.duration)) && 
                                    <Button size="sm" style={{width: '100%'}} color="warning" disabled>Live Now</Button>
                                }
                            </Col>
                        </Row>
                    </VerticalTimelineElement>
                })
            }
            {myTimezone && showMyTimezone && 
                sessions.sort((s1, s2) => s1.dateTime > s2.dateTime ? 1 : -1).map((s, i) => {
                    var date = new Date(s.dateTime);
                    var dt = date.toDateString('en-US', {timeZone: showMyTimezone ? myTimezone : timezone}) + ' ' + ( showMyTimezone ? calcLocalTime(date.getHours() + ":" + date.getMinutes()) : date.getHours() + ":" + date.getMinutes());
                    //s.dateTime.split('T')[0].split('-').reverse().join('-') + ' ' + s.dateTime.split('T')[1].substr(0, 5) + ', Duration: ' + s.duration + ' minutes'
                    return <VerticalTimelineElement key={i}
                        date={dt}
                        iconStyle={{ background: new Date(s.dateTime) < new Date(Date.now()) && new Date(s.dateTime) > new Date(Date.now() - (1000 * 60 * s.duration)) ? '#0093ff' : '#bbb', color: '#fff', paddingLeft: '10px' }}
                        icon={<FontAwesomeIcon icon={faCalendar} />}>
                        <h3 className="vertical-timeline-element-title">{s.title}</h3>
                        <p>
                            {s.description}
                        </p>
                        <hr />
                        <Row>
                            <Col md="6">
                                <a className="btn btn-danger btn-sm" style={{width: '100%'}}
                                    href={s.url} target="_blank">Watch Online</a>
                            </Col>
                            <Col md="6">
                                {
                                    new Date(s.dateTime) < new Date(Date.now()) && new Date(s.dateTime) > new Date(Date.now() - (1000 * 60 * s.duration)) && 
                                    <Button size="sm" style={{width: '100%'}} color="warning" disabled>Live Now</Button>
                                }
                            </Col>
                        </Row>
                    </VerticalTimelineElement>
                })
            }
        </VerticalTimeline>
    </>
}

export default Agenda;