import React, { useEffect, useState } from 'react';
import { Redirect, useParams } from 'react-router';
import { Row, Col, Card, CardBody, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookmark, faEnvelope, faStar } from '@fortawesome/free-regular-svg-icons';
import { AuthService } from '../../Services/AuthService';
import FileService from '../../Services/FileService';
import { useHistory } from 'react-router';
import ReactImageVideoLightbox from 'react-image-video-lightbox';
import Masonry from 'react-masonry-component';
import { EventService } from '../../Services/EventService';

const Company = () => {

    const user = JSON.parse(localStorage.getItem('digitalEventUser'));
    const [company, setCompany] = useState({});
    const { companyId } = useParams();
    const [showingLightbox, setShowingLightbox] = useState(false);
    const [initImage, setInitImage] = useState(0);
    const [active, setActive] = useState('profile');
    const [event, setEvent] = useState({});
    const [activities, setActivities] = useState([]);
    const authService = new AuthService();
    const fileService = new FileService();
    const history = useHistory();
    const eventService = new EventService();
    const masonryOptions = {
        transitionDuration: 0
    };

    if(!user){
        return <Redirect to="/networking/login" />
    }

    useEffect(() => {
        authService.getCompany(companyId).then(r => setCompany(r));
        eventService.getEvent(1).then(r => {
            setEvent(r);
            setActivities(JSON.parse(r.companyActivities));
        })
    }, []);

    const arrangeAppointment = (userId) => {
        history.push(`/networking/appoint/${userId}`);
    }

    return <>
        <Row>
            <Col md="12" style={{ marginLeft: '10px', marginTop: '25px', marginBottom: '30px' }}>
                <h4 className="text-primary bold" style={{fontWeight: '900', fontSize: '28px'}}>{ company.title } &gt; Profile</h4>
            </Col>
        </Row>
        <Row>
            <Col md="3">
                <Card>
                    <div className="text-center">
                        <img src={company.logo ? fileService.getUrl(JSON.parse(company.logo)[0].name) : ''} style={{width: '180px'}}/>
                    </div>
                    <br />
                    <h3 className="text-warning">{company.title}</h3>
                    <h5>{company.city}, {company.country}</h5>
                    <br />
                    <Button disabled style={{background: activities.filter(a => a.name === company.activity)[0] ? activities.filter(a => a.name === company.activity)[0].color : 'success'}}>{company.activity}</Button>
                    <hr />
                    <Button style={{width: '100%'}} color="primary" disabled={active === 'profile'} onClick={() => setActive('profile')}>Profile</Button>
                    <br />
                    <Button style={{width: '100%'}} color="primary" disabled={active === 'users'} onClick={() => setActive('users')}>Users</Button>
                    <br />
                    <Button style={{width: '100%'}} color="primary" disabled={active === 'media'} onClick={() => setActive('media')}>Media</Button>
                    <br />
                    <Button style={{width: '100%'}} color="primary" disabled={active === 'contact'} onClick={() => setActive('contact')}>Contact</Button>
                </Card>
            </Col>
            <Col md="9">
                <div className="text-center" className="myBg" style={{height: '300px',
                    background: `url(${company.mainImage ? fileService.getUrl(JSON.parse(company.mainImage)[0].name) : ''})`
                    }}>
                </div>
                <br />
                <Card>
                    {
                        active === 'profile' && <>
                            <h3 className="text-primary">Description</h3>
                            <hr />
                            <p dangerouslySetInnerHTML={{__html: company.description}}>
                                
                            </p>
                        </>
                    }
                    {
                        active === 'users' && <>
                        <h3 className="text-primary">Users</h3>
                        <hr />
                        <Row>
                        {
                            company && company.users && company.users.map(p => {
                                return <Col md="3" key={p.userId}>
                                    <Card>
                                        <CardBody>
                                            <Row>
                                                <Col md="3">
                                                    <img src="/pic.png" alt="profile" style={{ height: '60px' }} className="circular--square" />
                                                </Col>
                                                <Col md="9">
                                                    <span className="float-end text-primary" style={{ fontSize: '25px' }}><FontAwesomeIcon icon={faBookmark} /></span>
                                                </Col>
                                            </Row>
                                            <Row style={{ marginTop: '5px' }}>
                                                <Col md="12">
                                                    <h6>{p.name}</h6>
                                                    <p>{p.roleInCompany}</p>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md="12">
                                                    {
                                                        user.userId !== p.userId && <>
                                                            <Button onClick={() => arrangeAppointment(p.userId)} color="warning" size="sm" style={{ marginRight: "10px" }}>Meet</Button>
                                                            <Button size="sm" color="primary" onClick={() => history.push(`/networking/profile/${p.userId}`)}>View Profile</Button>
                                                        </>
                                                    }
                                                    
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                    
                                </Col>
                            })
                        }
                        </Row>
                        </>
                    }
                    {
                        active === 'media' && <>
                            <h3 className="text-primary">Media</h3>
                            <hr />
                            {
                                company.images && showingLightbox && <ReactImageVideoLightbox style={{backgroundColor: '#fff!important'}}
                                data={JSON.parse(company.images).map((im, i) => {
                                    if(im.name){
                                        return {
                                            url: fileService.getUrl(im.name),
                                            type: "photo",
                                            altTag: "img",
                                        }
                                    }
                                    else if(im.youtubeLink){
                                        return {
                                            url: im.youtubeLink,
                                            type: "video",
                                            title: "Youtube Video"
                                          }
                                    }
                                    })
                                }
                                    startIndex={initImage}
                                    showResourceCount={true}
                                    onCloseCallback={() => setShowingLightbox(false)}
                                    >
    
                                </ReactImageVideoLightbox>
                            }
                            
                            <Row>
                                {
                                    company.images && !showingLightbox && 
                                    <Masonry style={{width: '100%'}}
                                        className={'my-gallery-class'} // default ''
                                        elementType={'ul'} // default 'div'
                                        options={masonryOptions} // default {}
                                        disableImagesLoaded={false} // default false
                                        updateOnEachImageLoad={false} // default false and works only if disableImagesLoaded is false
                                        
                                    >
                                    {
                                        JSON.parse(company.images).map((im, i) => {
                                            if(im.name){
                                                return <li key={i} className="image-element-class" style={{width: '31%', padding: '2px'}} >
                                                        <img  src={fileService.getUrl(im.name)} style={{width: '100%'}} 
                                                            onClick={() => {
                                                                setInitImage(i);
                                                                setShowingLightbox(true);
                                                            }}/>
                                                    </li>
                                            }
                                            else if(im.youtubeLink){
                                                return <li key={i} className="image-element-class" style={{width: '31%', paddin: '2px'}}>
                                                    <iframe width="100%" height="250" src={im.youtubeLink} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                                </li>
                                            }
                                        })
                                    }
                                    </Masonry>
                                }
                            </Row>
                        </>
                    }
                    {
                        active === 'contact' && <>
                            <h3 className="text-primary">Contact Information</h3>
                            <hr />
                            {/* <p><FontAwesomeIcon icon={faEnvelope} /> <a href={"mailto:" + company.email}>{company.email}</a></p>
                            <hr />
                            <p><FontAwesomeIcon icon={faStar} /> {company.phone}</p>
                            <hr /> */}
                            <p><FontAwesomeIcon icon={faBookmark} /> <a href={company.address.includes('http') ? company.address : 'https://' + company.address} target="_blank">Visit Website</a></p>
                        </>
                    }
                </Card>
            </Col>
        </Row>
    </>;
};

export default Company;